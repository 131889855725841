'use strict';

angular.module('enervexSalesappApp').controller('StackRuleCtrl', function($scope, StackRule, StackRulePhase, $stateParams, Flash, $state, _, Product, StackRuleConditionType, Manufacturer, ProductType, Application, $q, Util) {
	$scope.errors = {};
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		$scope.manufacturer = _.find(result, function(o){
			return o.isDefault
		})
	})
	if ($stateParams.id == "new") {
		$scope.item = {
			// conditionType:'selected',
			actionType: 'product',
			active: true,
			condition:{
			},
			isSectionMatcher: false,
			variableTypes: [],
			fixedTypes: []
		}
		setup();
	} else {
		StackRule.get({
			id: $stateParams.id
		}).$promise.then(function(item) {
			$scope.item = item;
			setup();
		})
	}
	$scope.ctx = {
		formChanged:false
	}
	$scope.stackRulePhases = StackRulePhase.query();
	$scope.selectPhase = function(){
		var phase = _.find($scope.phases,function(c){
			return c._id == $scope.item.stackRulePhase;
		})
		$scope.stackRulePhase = stackRulePhase;
	}
	$scope.applianceCategories = [
		{
			name: "II",
			_id: "II"
		},{
			name: "IV",
			_id: "IV"
		},{
			name: "III",
			_id: "III"
		},{
			name: "I - Direct Connect",
			_id: "I - Direct Connect"
		},{
			name: "I - Draft Hood",
			_id: "I - Draft Hood"
		},{
			name: "I - Fan Assisted",
			_id: "I - Draft Hood"
		},{
			name: "Oil",
			_id: "Oil"
		}
	]
	function setup() {
		$scope.selectedLogics = [
			'selected',
			'not selected'
		]
		$scope.conditionTypes = StackRuleConditionType.query();
		$scope.actionTypes = [
			"javascript",
			"product"
		];
		$scope.shapes = [
			"ROU",
			"SQU",
			"REC",
			"OVA"
		];
		var computeAses = [
			{
				code: "COM"
			},{
				code: "BWH"
			}, {
				code: "DRY"
			}
		];
		$scope.computeAses = _.map(computeAses, function(computeAs){
			var existing = _.find($scope.item.condition.computeAses, function(v){
				return v == computeAs.code
			})
			if (existing){
				computeAs.selected = true
			}
			return computeAs
		})
		$scope.conditionLogics = ['==', '>', '<', '<=', '>='];
		$scope.existsLogic = ['==', '!='];
		$scope.booleanOptions = [
			{
				name: "true",
				value: true
			},
			{
				name: "false",
				value: false
			}
		]
		$q.all([
			ProductType.query().$promise,
			Application.query().$promise
		]).then(function(result){
			var productTypes = result[0]
			var applications = result[1]
			$scope.productTypes = productTypes
			if (!$scope.item.criterias) {
				$scope.item.criterias = []
			}
			_.each($scope.item.criterias, function(criteria){
				if (!criteria._id) {
					criteria._id = Util.objectId()
				}
				criteria.applianceCategories = _.map($scope.applianceCategories, function(_applianceCategory){
					var applianceCategory = _.clone(_applianceCategory)
					var existing = _.find(criteria.applianceCategories, function(o){
						return _.isString(o) ? o == applianceCategory._id :  o._id == applianceCategory._id
					})
					if (existing) {
						if (_.isString(existing)){
							applianceCategory.selected = true
							return applianceCategory
						} else {
							return existing
						}
					} else {
						return applianceCategory
					}
				})
				criteria.applications = _.map(applications, function(_application){
					var application = _.clone(_application)
					var existing = _.find(criteria.applications, function(o){
						return o == application._id
					})
					if (existing) {
						application.selected = true
					}
					return application
				})
			})
		})
	}
	function guardBoolean(object, attribute){
		var val = object[attribute];
		if (val !== true && val !== false){
			object[attribute] = null
		}
	}
	function guardString(object, attribute){
		if (object[attribute] == ''){
			object[attribute] = null
		}
	}
	function guardNumber(object, attribute){
		if (object[attribute] && object[attribute] != ''){
			object[attribute] = parseFloat(object[attribute])
		}
	}
	$scope.removeCriteria  =function(value) {
		$scope.item.criterias = _.filter($scope.item.criterias, function(o){
			return o != value
		})
	}
	$scope.addCriteria  =function() {
		var criteria = {
			_id: Util.objectId(),
			restrictApplianceCategories: false,
			applianceCategories:_.map($scope.applianceCategories, function(o){
				var result = _.clone(o)
				result.selected = false
				return result
			})
		}
		$scope.item.criterias.push(criteria)
	}
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		data: function (term) {
			return Product.query({
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id
			}).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			$scope.ctx.formChanged = true;
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.item.product = fullProduct;
		}
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v");
			payload.criterias = _.map(payload.criterias, function(_o){
				var o = _.clone(_o)
				o.applianceCategories = _.compact(_.map(o.applianceCategories, function(applianceCategory){
					return applianceCategory.selected ? applianceCategory._id : null
				}))
				o.applications = _.compact(_.map(o.applications, function(application){
					return application.selected ? application._id : null
				}))
				delete o.showAllAvailableApplianceCategories
				return o
			})
			if (payload.condition.computeAsesLogic == 'selected' || payload.condition.computeAsesLogic == 'not selected'){
				payload.condition.computeAses = _.pluck(_.where($scope.computeAses, {selected:true}), "code")
			} else {
				payload.condition.computeAses = []
			}

			if (!$scope.item._id) {
				StackRule.save(payload).$promise.then(function(result) {
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully created StackRule.', 0, {}, true);
					$state.go("stack-rule", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				StackRule.update({
					id: $scope.item._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated StackRule.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});